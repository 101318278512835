import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostHogProvider} from 'posthog-js/react'
import * as Sentry from "@sentry/react";

const options = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
}
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async function() {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
      
      // Function to trigger batch processing
      window.triggerImageBatchProcessing = () => {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage('process-queue');
        }
      };
    } catch (err) {
      console.log('ServiceWorker registration failed: ', err);
    }
  });
}


Sentry.init({
  dsn: "https://b50f939d9e4d8af16dcc264842f7abc9@o4508773432950784.ingest.us.sentry.io/4508777897525248",
  environment: process.env.REACT_APP_NODE_ENV, //  'production', 'staging', etc.
  beforeSend: function(event) {
    if (event.environment !== 'production') {
      return null;
    }
      // Filter out React DOM and ref-related warnings
   else if (event.message && (
        // React DOM warnings
        event.message.includes('Warning: ReactDOM') ||
        event.message.includes('Warning: Invalid DOM') ||
        event.message.includes('Warning: React') ||
        event.message.includes('Warning: Invalid prop') ||
        event.message.includes('Warning: Each child') ||
        event.message.includes('Warning: Failed prop type') ||
        // Ref-related warnings
        event.message.includes('Warning: Function components cannot be given refs') ||
        event.message.includes('Warning: Unexpected ref object provided') ||
        event.message.includes('Warning: String refs are not supported') ||
        event.message.includes('Warning: Ref forwarding is not supported') ||
        event.message.includes('Warning: Cannot update a component') ||
        event.message.includes('ref.current') ||
        event.message.includes('forwardRef')
      )) {
        return null;
      }
   else  return event;
  },
  ignoreErrors: [
    // Common React warnings to ignore
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    // Ref-related errors to ignore
    'Invalid attempt to spread non-iterable instance',
    'Cannot read property current of null',
    'Cannot read property \'current\' of undefined',
    'Cannot read properties of null (reading \'current\')',
    'Cannot read properties of undefined (reading \'current\')'
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserApiErrorsIntegration(), // Correct integration
    Sentry.captureConsoleIntegration(({
      levels: ['error'] // Only capture error-level messages
    }))
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/prod\.foosh\.ai/,
    /^https:\/\/staging\.foosh\.ai/
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_POSTHOG_KEY}
      options={options}
    >
    <App />
    </PostHogProvider>
  </React.StrictMode>
);
const script = document.createElement('script');
script.src = 'https://checkout.razorpay.com/v1/checkout.js';
script.async = true;
document.body.appendChild(script);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
