import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const LazyImage = ({ src, alt, className, onClick }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
    // img.onerror = () => setError(true);
  }, [src]);
  
  const handleImageError = (e) => {
    e.target.onerror = null;

    setRetryCount(prevCount => {
      if (prevCount >= 4) {
        setError(true);
        return prevCount;
      }
      
      e.target.src = src;
      return prevCount + 1;
    });
  };


  if (error) {
    return <div className={`${className} bg-gray-200 flex items-center justify-center`}>Error loading image</div>;
  }

  return (
    <div className={`${className} `}>
      {!loaded && (
        <div className=" absolute inset-0 flex flex-1 rounded-[16px] items-center justify-center bg-gradient-to-b  from-white to-gray-100 bg-opacity-20">
          {/* <Loader2 className="animate-spin" />
           */}
           <div className="flex rounded-[16px] flex-1 bg-gradient-to-b  from-white to-gray-100 bg-opacity-20"></div>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onError={handleImageError}
        className={`${className} ${loaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        onClick={onClick}
      />
    </div>
  );
};

export default LazyImage;