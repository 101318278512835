import React, { useState, useEffect, useRef } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import InstructionPanel from './InstructionPanel';
import GeneratedImages from './GeneratedImages';
import SelectedImage from '../components/SelectedImage';
import GenerationGroup from '../components/GenerationGroup';
import NuxImage from '../assets/icons/Group 669.jpg';

import './StudioView.css';
import { captureException } from '@sentry/react';

const StudioView = ({ setUpscaleQueue, setCompletedUpscale }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [generatedGroups, setGeneratedGroups] = useState([]);
  const [showNux, setShowNux] = useState(false);
  const [nuxUser, setNuxUser] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useUser();
  const { getToken } = useAuth();
  const [scrollManger, setScrollManger] = useState(false);
  const [newGenerationId, setNewGenerationId] = useState(null);
  const [newGenerationImages, setNewGenerationImages] = useState([]);
  const [newGenerationPrompt, setNewGenerationPrompt] = useState(null);
  const [newGenerationAspectRatio, setNewGenerationAspectRatio] =
    useState(null);
  const [scrollDetect, setScrollDetect] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user || !isGenerating) {
      fetchGeneratedGroups(1);
    }
  }, [user]);

  const fetchGeneratedGroups = async pageNum => {
    if (!user || isLoading) return;

    try {
      setIsLoading(true);
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generated-images?page=${pageNum}&limit=6&filter=user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        if (data.images.length <= 1 && pageNum === 1) {
          setShowNux(true);
        } else {
          setNuxUser(false);
          setShowNux(false);
        }
        const newGroups = data.images || [];

        setGeneratedGroups(prevGroups =>
          pageNum === 1 ? newGroups : [...prevGroups, ...newGroups]
        );
        setTotalPages(data.total_pages);
        setHasMore(newGroups.length === 6);
        setPage(pageNum);
      }
    } catch (error) {
      captureException("generation group in the studio :",error)
      console.error('Error fetching generated groups:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerationStart = ({ promptText, aspectRatio }) => {
    setNewGenerationId('generating');
    setNewGenerationImages(Array(4).fill(null));
    setNewGenerationPrompt(promptText);
    setNewGenerationAspectRatio(aspectRatio);
    setIsGenerating(true);
  };

  const handleImageGeneration = newImage => {
    // Update the generation id if it is not present
    if (newGenerationId === 'generating') {
      setNewGenerationId(newImage.image.generation_id);
    }
    setNewGenerationImages(prevImages => {
      const updatedImages = [...prevImages];
      const emptyIndex = updatedImages.findIndex(img => img === null);
      if (emptyIndex !== -1) {
        updatedImages[emptyIndex] = newImage.image;
      } else {
        console.warn('No empty slot found for new image');
      }

      if (emptyIndex === 3) {
        setIsGenerating(false);
        setGeneratedGroups(prevGroups => {
          return [
            {
              aspect_ratio: newImage.aspectRatio,
              prompt: newImage.image.prompt,
              images: updatedImages,
              generation_id: newImage.image.generation_id,
            },
            ...prevGroups,
          ];
        });
      }

      return updatedImages;
    });
  };

  const handleGenerationEnd = () => {
    setIsGenerating(false);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    if (nextPage <= totalPages) fetchGeneratedGroups(nextPage);
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        !isLoading &&
        hasMore
      ) {
        // 100px before the end
        loadMore();
      }
    }
    setScrollDetect(true);
  };
  const handleNextImage = () => {
    if (!selectedImage || !generatedGroups.length) return;
    
    const groupIndex = generatedGroups.findIndex(g => g.generation_id === selectedImage.image.generation_id);
    const currentGroup = generatedGroups[groupIndex];
    const imageIndex = currentGroup.images.findIndex(img => img.image_id === selectedImage.image.image_id);
    
    if (imageIndex < currentGroup.images.length - 1) {
      setSelectedImage({ image: currentGroup.images[imageIndex + 1] });
    } else if (groupIndex < generatedGroups.length - 1) {
      setSelectedImage({ image: generatedGroups[groupIndex + 1].images[0] });
    }else{
      //this to disable the next button 
      return true ;
    }
    //this is to keep next button working 
    return false;
  };
  
  const handlePrevImage = () => {
    if (!selectedImage || !generatedGroups.length) return;
    
    const groupIndex = generatedGroups.findIndex(g => g.generation_id === selectedImage.image.generation_id);
    const currentGroup = generatedGroups[groupIndex];
    const imageIndex = currentGroup.images.findIndex(img => img.image_id === selectedImage.image.image_id);
    
    if (imageIndex > 0) {
      setSelectedImage({ image: currentGroup.images[imageIndex - 1] });
    } else if (groupIndex > 0) {
      const prevGroup = generatedGroups[groupIndex - 1];
      setSelectedImage({ image: prevGroup.images[prevGroup.images.length - 1] });
    }else{
      //to disable the prev button
      return true;
    }
    //to not disable the prev button
    return false;
  };
  return (
    <div className="studio-container  overflow-hidden flex flex-col w-full flex-1">
      {selectedImage && (
        <SelectedImage
          selectedImage={selectedImage}
          handleNextImage={handleNextImage}
          handlePrevImage={handlePrevImage}
          generatedGroups={generatedGroups}
         setGeneratedGroups={setGeneratedGroups}
          onClickCancel={() => setSelectedImage(null)}
        />
      )}
      <div
        className={`instruction-panel bg-[#F0F0F0] 
            ${scrollManger ? 'fixed top-15 left-0 right-0 z-10' : ''}
        `}
      >
        <InstructionPanel
          onGenerateImage={handleImageGeneration}
          nuxUser={nuxUser}
          onGenerationStart={handleGenerationStart}
          onGenerationEnd={handleGenerationEnd}
          setScrollManger={setScrollManger}
          scrollDetect={scrollDetect}
          setScrollDetect={setScrollDetect}
          showNux={showNux}
          setShowNux={setShowNux}
        />
      </div>
      <div
        className={`generation-panel  flex-grow flex flex-col overflow-hidden ${
          scrollManger ? 'fixed top-[245.984375px] left-0 right-0 bottom-0' : ''
        }`}
      >
        <div
          ref={scrollContainerRef}
          className={`w-full flex-grow overflow-y-auto ${
            scrollManger ? 'h-full' : ''
          }`}
          onScroll={handleScroll}
        >
          <div className="p-4">
            {isGenerating && (
              <GenerationGroup
                showPrompt={true}
                aspectRatio={newGenerationAspectRatio}
                generationId={newGenerationId}
                images={newGenerationImages}
                prompt={newGenerationPrompt}
                setSelectedImage={setSelectedImage}
                setUpscaleQueue={setUpscaleQueue}
                setCompletedUpscale={setCompletedUpscale}
              />
            )}
            <GeneratedImages
              showPrompt={true}
              setUpscaleQueue={setUpscaleQueue}
              setCompletedUpscale={setCompletedUpscale}
              generatedGroups={generatedGroups}
              loadMore={loadMore}
              hasMore={hasMore}
              setSelectedImage={setSelectedImage}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {generatedGroups.length === 0 && !isLoading && !isGenerating && (
        <div className={`flex flex-1 h-full `}>
          <img
            className={`w-full h-[35vh] ${
              scrollManger ? ' fixed bottom-0 right-0 w-full' : ''
            }`}
            src={NuxImage}
            alt="nuxImage"
          />
        </div>
      )}
    </div>
  );
};

export default StudioView;
