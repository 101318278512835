// src/components/LikedUpscalesView.jsx
import React,{useState,useEffect} from 'react';
import { useLikedUpscales } from '../../API';
import ImageGrid from './ImagesGrid';
import Loader from '../../assets/icons/loader.svg';
import SelectedImage from '../../components/SelectedImage';
const LikedUpscalesView = ({
  activeTab,
  setUpscaleQueue,
  setCompletedUpscale,
  selectedModel = [],
  LkView = false,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const { data, isLoading, isError, error, hasNextPage, fetchNextPage } =
    useLikedUpscales({
      activeTab: activeTab,
      limit: 6,
      isLkView: LkView,
      payload: {
        models: selectedModel,
        // other payload properties like:
        // filter: 'org',
        // any other properties you want to send in the POST request
      },
    });

    useEffect(()=>{
      if(data){
         // Flatten the pages to get all images
   const imagesData = data.pages.flatMap(page => page.images);
        setImages(imagesData)
      }
    },[data])

  if (isLoading)
    return (
      <div className="w-full text-xl font-bold flex h-full justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );

  if (isError) return <div>Error: {error.message}</div>;
 


console.log(images,"=======");
 const handleNextImage = () => {
   
   if (!selectedImage) return;
 
   
   const currentIndex = images.findIndex(img =>img.image_id === selectedImage.image.image_id);
   console.log(currentIndex)
   if (currentIndex < images.length - 1) {
     setSelectedImage({ image: images[currentIndex + 1] });
   }else{
    //disable the next button 
      return true;
   }
   //keep the next button working
   return false;
 };

 const handlePrevImage = () => {
  
   if (!selectedImage) return;

   const currentIndex = images.findIndex(img => img.image_id === selectedImage.image.image_id);
   if (currentIndex > 0) {
     setSelectedImage({ image:images[currentIndex - 1] });
   }else{
      //disable the prev button
      return true;
   }
    //keep the prev button working
    return false;
 };
  return (
    <div className="flex flex-1 h-full w-full ">
      {selectedImage && (
        <SelectedImage
          selectedImage={selectedImage}
          generatedGroups={images}
          setGeneratedGroups={setImages}
          handleNextImage={handleNextImage}
          handlePrevImage={handlePrevImage}
          onClickCancel={() => setSelectedImage(null)}
          likeUpscaleGallery={true}
        />
      )}
     
      <ImageGrid
        activeTab={activeTab}
        LkView={LkView}
        images={images}
        setSelectedImage={setSelectedImage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        setCompletedUpscale={setCompletedUpscale}
        setUpscaleQueue={setUpscaleQueue}
        generatedGroups={images}
        setGeneratedGroups={setImages}
        likeUpscaleGallery={true}
      />
    </div>
  );
};

export default LikedUpscalesView;
