export const productDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-1.png",
        description: "Well lit pictures"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-2.png",
        description: "Consistent product"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-3.png",
        description: "Main focus is the product"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-do-4.png",
        description: "Zoom in on details"
    }
];
export const productDonts = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-1.png",
        description: "Avoid bad angles"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-2.png",
        description: "Avoid heavy backgrounds"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-3.png",
        description: "No blurry images"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/product-dont-4.png",
        description: "Train sides separately"
    }
];

export const personDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-3.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-9.png",
        description:null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-7.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-8.png",
        description: null
    }
];

export const personDonts = [
   
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-1.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-2.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-5.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/person-do-6.png",
        description: null
    }
];

export const petDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-11.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-12.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-7.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-8.png",
        description: null
    }
];

export const petDonts = [
  
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-9.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-10.png",
        description: null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-5.png",
        description:null
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/pet-do-6.png",
        description: null
    }
];

export const eyewearDos = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-do-1.png",
        description: "Well lit pictures"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-do-2.png",
        description: "Consistent product"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-do-3.png",
        description: "Showcase fitting"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-do-4.png",
        description: "Zoom in on details"
    }
];
export const eyewearDonts = [
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-dont-1.png",
        description: "No cluttered crops"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-dont-2.png",
        description: "No irrelevant angles"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-dont-3.png",
        description: "No blurry images"
    },
    {
        url: "https://d2h4y8xaq7mn9l.cloudfront.net/eyewear-dont-4.png",
        description: "No busy images"
    }
];