import React, { useState,useCallback,useRef,useEffect } from 'react';
import { ChevronDown, Search,ChevronUp } from 'lucide-react';
import { useModels } from '../../API';
import _ from 'lodash';

const ModelListSidebar = ({ selectedModel, setSelectedModel,fixedIn=false}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceSearchTerm,setDebounceSearchTerm]=useState('');
  const [showModels,setShowModels]=useState(!fixedIn);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const referenceRef = useRef(null);
  
  useEffect(() => {
    const updatePosition = () => {
      if (referenceRef.current) {
        const rect = referenceRef.current.getBoundingClientRect();
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX
        });
      }
    };

    // Initial position
    updatePosition();

    // Update position on scroll and resize
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useModels('your_models', { q: debounceSearchTerm });
  // Create a debounced function using lodash's debounce
  const debouncedSearch = useCallback(_.debounce((search) => {
    setDebounceSearchTerm(search);
    setShowModels(()=>true);
  }, 500), []);

  // Event handler for input changes
  const handleSearch = (event) => {
    debouncedSearch(event.target.value);
    setSearchTerm(event.target.value)
  };
  const loadMoreRef = useRef(null);

  // Intersection observer to call fetchNextPage when the button is near viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '30px', // distance from the bottom of the viewport
        threshold: 0.1, // trigger when 10% of the element is visible
      }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [showModels,hasNextPage, isFetchingNextPage, fetchNextPage]);

  // if (isLoading) return <div className="flex flex-col h-full flex-1 gap-2 border-r-[1px]">Loading...</div>;
  if (error) return <div>Error loading models</div>;

  return (
    <div className="flex flex-col h-full flex-1 gap-2 border-r-[1px] ">
      {/* Search Input */}
      <div ref={referenceRef} className="flex w-full p-2 border-b-[1px] justify-center " >
        <div className="flex w-full border-[1px] p-2 gap-2 rounded-full">
          <input
            className="outline-none flex w-full"
            placeholder="Search Products"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search />
        </div>
       {fixedIn&& <button onClick={()=>setShowModels((prev)=>!prev)}>{showModels?<ChevronUp/>:<ChevronDown/>}</button>}
      </div>
      {isLoading && !fixedIn &&<div className="flex pl-4 flex-col h-full flex-1 gap-2 ">Loading...</div>}

{!isLoading&& data && showModels && <div className={`${fixedIn ? 'fixed z-10 flex bg-white flex-col w-[17.3%] h-[50vh]' : 'flex flex-1 flex-col overflow-scroll'}`}
style={{ top: fixedIn ? `${position.top}px` : 'auto' }}>
      {/* Model Selection Info */}
      <div  className="w-full pl-4 flex border-b-[1px] p-2 text-sm">
        <div className="flex flex-1">
          {selectedModel.length > 0
            ? `${selectedModel.length} ${selectedModel.length === 1 ? 'product' : 'products'} selected`
            : `${data?.pages[0].totalCount} ${data?.pages[0].totalCount === 1 ? 'product' : 'products'}`}
        </div>
        {selectedModel.length > 0 && (
          <div
            className="flex text-sm cursor-pointer text-red-600"
            onClick={() => setSelectedModel([])}
          >
            Deselect all
          </div>
        )}
      </div>

      {/* Models List */}
      <div className="w-full pl-2  pt-2 flex flex-1 flex-col overflow-y-scroll gap-2 ">
        {data?.pages.map((page, i) => (
          <React.Fragment key={i}>
            {page.loras
              .filter(model => model?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
              .map(model => (
                <div key={model.id} className="flex w-full items-center gap-2 px-2">
                  <input
                    type="checkbox"
                    checked={selectedModel.some(item => item.id === model.id)}
                    onChange={() => {
                      setSelectedModel(prevSelected => {
                        if (prevSelected.some(item => item.id === model.id)) {
                          return prevSelected.filter(item => item.id !== model.id);
                        } else {
                          return [...prevSelected, model];
                        }
                      });
                    }}
                  />
                  <div className="w-[18%] bg-gray-200 aspect-1 border-[1px] rounded-md overflow-hidden">
                    <img src={model.thumbnailUrl} alt={model.name} />
                  </div>
                  {model.name}
                </div>
              ))}
          </React.Fragment>
        ))}

        {/* Load More Button */}
        {hasNextPage && (
    <div className="flex justify-center p-2">
      <button
        ref={loadMoreRef} // Set the ref here
        className="p-2  border-opacity-0  text-white rounded"
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        {isFetchingNextPage ? 'Loading more...' : 'Load More'}
      </button>
    </div>
  )}
      </div>

      </div>}
    </div>
  );
};

export default ModelListSidebar;