import React, { useState, useEffect } from 'react';
import { Heart, X, ChevronLeft, Copy,ChevronRight } from 'lucide-react';
import { toast } from 'react-toastify';
import { useUser, useAuth } from '@clerk/clerk-react';
import ImageSlider from './ImageSlider';
import { useImageDetails } from '../../API';
import Reimagine from '../../assets/icons/Reimagine Button.svg';
import { useNavigate } from 'react-router-dom';
import upscaleInProgGif from '../../assets/icons/upscale_lottie.gif';

const SelectedImage = ({ selectedImage,handleNextImage,
  handlePrevImage, onClickCancel,generatedGroups,
  setGeneratedGroups,likeUpscaleGallery=false }) => {
  const navigate = useNavigate();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [imageType, setImageType] = useState('original');
  const [likeLocal, setLikeLocal] = useState(
    selectedImage?.image.saved_to_gallery
  );
  // const [selectedImage,setSelectedImage]=useState(selectedImage);
  const [resolution, setResolution] = useState('');
  const [upscaleStatus, setUpscaleStatus] = useState(
    selectedImage?.image.upscale_status
  );
  useEffect(()=>{setUpscaleStatus(selectedImage?.image.upscale_status);setLikeLocal(selectedImage?.image.saved_to_gallery)},[selectedImage?.image.image_id]);
  const [isSaved, setIsSaved] = useState(
    selectedImage?.image.saved_to_gallery || false
  );
  const { data, isLoading, error } = useImageDetails(
    selectedImage?.image.image_id
  );
  const { user } = useUser();
  const { getToken } = useAuth();
  const updateUpscaleStatus = (generationGroups, generationId, img) => {
    if(likeUpscaleGallery){
        const updatedData= generatedGroups.map(image => {
          if (image.image_id === img.image_id && generationId === image.generation_id) {
            return {
              ...image,
              upscale_status: "REQUESTED"
            };
          }
          return image;
        })
        return updatedData;
    }else{

      const updatedData = generationGroups.map(generation => {
        if (generation.generation_id === generationId) {
          return {
            ...generation,
            images: generation.images.map(image => {
              if (image.image_id === img.image_id) {
                return {
                  ...image,
                  upscale_status: "REQUESTED"
                };
              }
              return image;
            })
          };
        }
        return generation;
      });
  
      return updatedData;
    }
  };
  const handleUpscale = async (image) => {
    if (!user) return;
    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/queue_upscale`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          generation_id: image.generation_id,
          image_id: image.image_id,
        }),
      });

      if (response.status === 475) {
        const toastId = toast.error(
          <div className="flex justify-between items-center w-full">
            <span className="flex-grow">
              Insufficient credits for this task{' '}
            </span>
            <button
              onClick={() => {
                toast.dismiss(toastId);
                navigate('/billing');
              }}
              className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
            >
              Add credits
            </button>
          </div>,
          {
            position: 'bottom-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: 'w-full max-w-md',
          }
        );
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        if(errorData.detail==='Image upscale already requested!'){
          toast.warning(`Image upscale already requested!`, {
              position: 'bottom-center',
          });
          return;
      }else{

          throw new Error(errorData.error || 'Failed to request upscale');
      }
      }
      const updatedData = updateUpscaleStatus(generatedGroups, image.generation_id, image);
      setGeneratedGroups(updatedData);
      setUpscaleStatus("REQUESTED");
      toast.success('Upscale requested, please check after 5 mins', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error requesting upscale :', error);
      if (error.message === 'Insufficient credits'){
      toast.error(
        <div>
          SelectedImage upscale error.
          <button
            onClick={() => navigate('/billing')}
            className="ml-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
          >
            Add more credits
          </button>
        </div>,
        {
          position: 'bottom-center',
          autoClose: false,
          closeOnClick: false,
        }
      );}
    }
  };

  const updateFavStatus = (generationGroups, generationId, img) => {
    if(likeUpscaleGallery){
        const updatedData= generatedGroups.map(image => {
          if (image.image_id === img.image_id && generationId === image.generation_id) {
            return {
              ...image,
              saved_to_gallery: !likeLocal
            };
          }
          return image;
        })
        return updatedData;
    }else{

      const updatedData = generationGroups.map(generation => {
        if (generation.generation_id === generationId) {
          return {
            ...generation,
            images: generation.images.map(image => {
              if (image.image_id === img.image_id) {
                return {
                  ...image,
                  saved_to_gallery: !likeLocal
                };
              }
              return image;
            })
          };
        }
        return generation;
      });
  
      return updatedData;
    }
  };
  const handleSaveToGallery = async (image) => {
    if (!user) return;
    try {
      const token = await getToken({ template: 'token' });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/save-to-gallery`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            generation_id: image.generation_id,
            image_id: image.image_id,
            like: !likeLocal,
          }),
        }
      );

      if (response.ok) {
        const updatedData = updateFavStatus(generatedGroups, image.generation_id, image);
      setGeneratedGroups(updatedData);
        toast.success(`Image ${!likeLocal ?"saved to":"removed from"}  gallery successfully!`, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setLikeLocal(!likeLocal);
        // setSelectedImage((prev)=> ( {...prev,image:{...image,saved_to_gallery:!image.saved_to_gallery}}));
        // setIsSaved(true); // Update local state
      } else {
        toast.error('Failed to save image to gallery', {
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error saving image to gallery:', error);
      toast.error('Failed to save image to gallery', {
        position: 'bottom-center',
      });
    }
  };

  const handleDownload = async imageUrl => {
    if (!imageUrl) {
      toast.error('Image URL is invalid.', {
        position: 'bottom-center',
      });
      return;
    }
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'custom_image_name.jpg'; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //window.open(imageUrl, '_blank');
  };

  const handleReimagine = async () => {
    if (!data?.data?.details?.models_used?.[0]) {
      console.error('No model data available');
      return;
    }

    try {
      // Navigate to studio
      navigate(`/studio?imageId=${selectedImage.image.image_id}`);
      onClickCancel();
    } catch (error) {
      console.error('Error storing reimagine data:', error);
      toast.error('Failed to set up reimagine. Please try again.');
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(selectedImage.image.prompt)
      .then(() => {
        toast.success('Prompt Copied Successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const getImageResolution = url => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve(`${img.width}px x ${img.height}px`);
      img.src = url;
    });
  };

  useEffect(() => {
    const updateResolution = async () => {
      const res = await getImageResolution(
        imageType === 'original'
          ? selectedImage?.image.image_url
          : selectedImage?.image.upscale_url
      );
      setResolution(res);
    };
    updateResolution();
  }, [imageType, selectedImage]);

  const formatDate = timestamp => {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date)) {
      return 'Invalid Date';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };
  if (!selectedImage) return null;
  return (
    <div
    key={selectedImage?.image?.image_id}
      className={`${selectedImage ? 'fixed' : 'hidden'
        } inset-0  top-0 flex w-full h-screen items-center bg-black z-50 `}
    >
      <button
        className="absolute top-0 left-0 p-2 bg-opacity-50 rounded-full hover:bg-opacity-75 transition-all duration-200 ease-in-out"
        onClick={onClickCancel}
      >
        <X size={24} className="text-white" />
      </button>
     {!disablePrev&& <button
        className="absolute top-1/2 left-0 p-2 bg-opacity-50 rounded-full hover:bg-opacity-75 transition-all duration-200 ease-in-out"
        onClick={()=>{
          const hasPrev=handlePrevImage();
          setDisablePrev(hasPrev);
        if(disableNext){
          setDisableNext(false)
        }
        }}
      >
        <ChevronLeft size={24} className="text-white" />
      </button>}
      <div className="flex w-[80%] p-2 h-full flex-1 bg-black bg-opacity-90 justify-center items-center overflow-hidden ">

      <div
          className={` ${['16:9', '4:3', '2:1'].includes(selectedImage?.image.aspect_ratio)
            ? 'w-[90%] h-auto '
            : 'h-[90%] w-auto'
            } p-2 flex  `}
        >
          <ImageSlider
          key={selectedImage?.image?.image_id}
            leftImage={selectedImage?.image?.upscale_url}
            rightImage={selectedImage?.image.image_url}
            aspectRatio={selectedImage?.image.aspect_ratio}
          />
        </div>
       
      </div>
    {!disableNext&&  <button
        className="absolute top-1/2 right-[20%] p-2 bg-opacity-50 rounded-full hover:bg-opacity-75 transition-all duration-200 ease-in-out"
        onClick={()=>{ 
          const hasNext=handleNextImage();
          setDisableNext(hasNext);
          if(disablePrev){
            setDisablePrev(false)
          }
          }}
      
      >
        <ChevronRight size={24} className="text-white" aria-disabled />
      </button>}
      <div className="w-[20%] flex h-full">
        <div className="flex w-full h-full flex-col p-4 bg-white border  ">
          {/* Header Section */}
          <div className="text-sm text-gray-500 mb-4 flex gap-2 ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6408 3.63998L20.3608 2.35998C20.2483 2.2463 20.1143 2.15605 19.9667 2.09446C19.8191 2.03287 19.6607 2.00116 19.5008 2.00116C19.3408 2.00116 19.1825 2.03287 19.0349 2.09446C18.8872 2.15605 18.7533 2.2463 18.6408 2.35998L2.36078 18.64C2.24709 18.7525 2.15685 18.8864 2.09526 19.0341C2.03367 19.1817 2.00195 19.34 2.00195 19.5C2.00195 19.6599 2.03367 19.8183 2.09526 19.9659C2.15685 20.1135 2.24709 20.2475 2.36078 20.36L3.64078 21.64C3.7526 21.7549 3.88631 21.8462 4.03402 21.9086C4.18173 21.971 4.34044 22.0031 4.50078 22.0031C4.66111 22.0031 4.81982 21.971 4.96753 21.9086C5.11524 21.8462 5.24896 21.7549 5.36078 21.64L21.6408 5.35998C21.7557 5.24816 21.847 5.11445 21.9094 4.96674C21.9717 4.81903 22.0039 4.66032 22.0039 4.49998C22.0039 4.33965 21.9717 4.18094 21.9094 4.03323C21.847 3.88552 21.7557 3.7518 21.6408 3.63998Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 7L17 10"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 6V10"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 14V18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 2V4"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 8H3"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 16H17"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 3H9"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="font-bold text-black">
              {' '}
              Imagined on {formatDate(selectedImage?.image.created_at)}
            </p>
          </div>

          <div className="mb-4">
            <p className="font-bold">
              {data?.data?.details?.models_used?.length}{' '}
              {data?.data?.details?.models_used?.length === 1
                ? 'model'
                : 'models'}{' '}
              in this shot:
            </p>
            <div className="flex items-center mt-2 h-10 w-10 bg-gray-100">
              {data?.data?.details?.models_used.map(model => {
                return (
                  <img
                    src={model?.thumbnailUrl}
                    key={model?._id}
                    alt="Model 1"
                    className="w-10 h-10 rounded-sm mr-2"
                  />
                );
              })}
            </div>
          </div>

          <div className="mb-4 p-2 h-[100px] border rounded-lg overflow-y-scroll">
            <div
              className="flex cursor-pointer text-xs items-center font-bold gap-2 mb-2"
              onClick={handleCopy}
            >
              <Copy className="p-1" /> Copy prompt
            </div>
            <p className="text-sm">{selectedImage.image.prompt}</p>
          </div>

          <div className="mb-4">
            <button
              className="w-full flex items-center gap-2 justify-between font-bold px-4 py-3 bg-black text-white rounded-3xl hover:bg-gray-900 transition duration-200"
              onClick={handleReimagine}
            >
              <div>Recreate this shot</div>
              <img src={Reimagine} alt="reiamgeine" />
            </button>
          </div>

          <div className="mb-4 text-start">
            <a
              href={selectedImage?.image.image_url}
              download="original-image.png"
              className="text-[#8E8D8D] text-[10px] tracking-small underline "
            >
              Download original shot {resolution}
            </a>
          </div>
          <div className="mb-4">
            <button
              onClick={() => {
                if (selectedImage?.image.upscale_url) {
                 handleDownload(selectedImage.image.upscale_url);
                } else {
                  if(upscaleStatus!=="REQUESTED")  handleUpscale(selectedImage.image);
                }
              }}
              className={`w-full flex py-1 items-center gap-2 px-6 justify-between border border-black rounded-3xl  transition duration-200 ${selectedImage.image?.upscale_status === 'REQUESTED'?'bg-[#EBEBEB] border-[#EBEBEB]':'bg-white border-black'} `}
            >
              <div className="flex text-[12px] text-start py-1 flex-col -gap-1 leading-tight">
                <div className="font-bold text-[14px] leading-tight">
                  {selectedImage.image?.upscale_status === 'UPSCALED'
                    ? 'Download '
                    : ''}{' '}
                  AI Upscale
                  {upscaleStatus === 'REQUESTED'
                    ? ' in progress'
                    : ' '}
                </div>
                <div className="leading-tight">2x Resolution</div>
              </div>
              {upscaleStatus === 'REQUESTED' && (
                <img className="w-6 h-6" src={upscaleInProgGif} alt="in Pro" />
              )}
              {upscaleStatus === 'NOTREQUESTED' && (
                <svg
                  width="23"
                  height="25"
                  viewBox="0 0 23 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.998 0L14.6347 2.6367L11.998 4L14.6347 5.3633L15.998 8L17.3613 5.3633L19.998 4L17.3613 2.6367L15.998 0Z"
                    fill="black"
                  />
                  <path
                    d="M20.998 8L20.3164 9.31835L18.998 10L20.3164 10.6817L20.998 12L21.6797 10.6817L22.998 10L21.6797 9.31835L20.998 8Z"
                    fill="black"
                  />
                  <path
                    d="M1 16L8 9L15 16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M8 9L8 24"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              )}
              {upscaleStatus === 'UPSCALED' && (
                <span>
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0L4.94381 4.94381L0 7.5L4.94381 10.0562L7.5 15L10.0562 10.0562L15 7.5L10.0562 4.94381L7.5 0Z"
                      fill="#6BCC63"
                    />
                    <path
                      d="M17 15L15.6367 17.3071L13 18.5L15.6367 19.6929L17 22L18.3633 19.6929L21 18.5L18.3633 17.3071L17 15Z"
                      fill="#6BCC63"
                    />
                  </svg>
                </span>
              )}
            </button>
          </div>

          <div className="text-center">
            <button
              onClick={() =>
                handleSaveToGallery(selectedImage.image)
              }
              className="flex items-center justify-between border border-black w-full px-6 py-2 rounded-3xl hover:bg-gray-100 transition duration-200"
            >
              <div className="font-bold">
                {likeLocal ? 'Added' : 'Add'} to Favorites
              </div>
              <span className="p-1">
                <Heart
                  fill={likeLocal ? 'red' : 'white'}
                  color={likeLocal ? 'red' : 'black'}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedImage;
