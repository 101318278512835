import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useFetchDriveData, useTriggerBulkVideo } from '../API';
import { Info, X, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { usePopper } from 'react-popper';
import Lottie from 'react-lottie-player';
import animationData from '../assets/icons/confirmation.json';

const BulkVideo = () => {
  const [driveUrl, setDriveUrl] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();
  const triggerBulkVideo = useTriggerBulkVideo();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const fetchDriveData = useFetchDriveData();

  const isValidGoogleDriveLink = (url) => {
    const pattern = /^https:\/\/drive\.google\.com\/(file\/d\/|drive\/folders\/|open\?id=)/;
    return pattern.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidGoogleDriveLink(driveUrl)) {
      toast.error('Invalid Google Drive URL format');
      return;
    }

    setLoading(true);
    try {
      // Pass true for 'models'
      const dataFetched = await fetchDriveData(driveUrl, true);
      setData(dataFetched);
      toast.success('Data fetched successfully!');
    } catch (error) {
      toast.error('Failed to fetch data from Google Drive');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateVideos = async () => {
    if (!isValidGoogleDriveLink(driveUrl)) {
      toast.error('Invalid Google Drive URL format');
      return;
    }
    if (!data) {
      toast.error('No data from Google Drive. Please fetch it first.');
      return;
    }

    setButtonDisabled(true);
    // Pass the entire JSON data under the key "drive_data"
    triggerBulkVideo.mutate(
      { drive_data: data },
      {
        onSuccess: () => {
          setShowConfirmation(true);
          toast.success('Video generation initiated!', {
            position: 'bottom-center',
          });
          setTimeout(() => {
            navigate('/models');
          }, 10000);
        },
        onError: () => {
          toast.error('Failed to generate videos. Please try again.', {
            position: 'bottom-center',
          });
          setButtonDisabled(false);
        },
      }
    );
  };

  const openPreview = (images, index) => {
    setPreviewImage(images[index]);
    setCurrentImageIndex(index);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  const showNextImage = (images) => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setPreviewImage(images[nextIndex]);
    setCurrentImageIndex(nextIndex);
  };

  const showPreviousImage = (images) => {
    const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
    setPreviewImage(images[prevIndex]);
    setCurrentImageIndex(prevIndex);
  };

  if (showConfirmation) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <Lottie
          loop={false}
          animationData={animationData}
          play
          style={{ width: 225, height: 225 }}
        />
        <p className="text-center mt-8 font-bold">
          Video generation initiated! You will receive an email when complete.
          Redirecting to "Your Models"...
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 pb-20">
      <h1 className="text-2xl font-bold mb-4">Bulk Video using Google Drive</h1>
      <form onSubmit={handleSubmit} className="mb-4 flex items-center space-x-2">
        <input
          type="text"
          value={driveUrl}
          onChange={(e) => setDriveUrl(e.target.value)}
          placeholder="Enter Google Drive URL"
          className="border p-2 flex-grow"
        />
        <button
          type="submit"
          className="bg-black text-white px-6 py-3 hover:bg-gray-800 flex items-center"
          disabled={loading}
          style={{ minWidth: '250px' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-5 h-5 mr-2 fill-current"
          >
            <path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
          </svg>
          {loading ? 'Loading...' : 'Upload Images through Google Drive'}
        </button>
        <div className="relative">
          <Info
            className="h-6 w-6 text-gray-500 cursor-pointer"
            ref={setReferenceElement}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          />
          {tooltipVisible && (
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="bg-black text-white text-sm rounded p-4 w-80 space-y-3 z-50"
            >
              <p className="font-semibold">How to get a public Google Drive link:</p>
              <ol className="list-decimal pl-4 space-y-2">
                <li>
                  <strong>Right-click</strong> the folder in Drive
                  <div className="text-gray-400 text-xs mt-1">
                    (Context menu with sharing options will appear)
                  </div>
                </li>
                <li>
                  Select <strong>"Share"</strong> → <strong>"Share"</strong>
                </li>
                <li>
                  Give access to <strong>google-drive-bulk@rosy-algebra-450819-p8.iam.gserviceaccount.com</strong>
                </li>
                <li>
                  Choose <strong>"Viewer"</strong> permission
                </li>
                <li>
                  Click <strong>"Copy link"</strong>
                </li>
              </ol>
              <div className="pt-2 border-t border-gray-700">
                <p className="text-xs text-gray-400">
                  💡 Valid links contain <code>drive.google.com</code> and typically end with:
                  <br />
                  <code>.../view?usp=sharing</code> or <code>.../uc?export=download</code>
                </p>
              </div>
            </div>
          )}
        </div>
      </form>

      {data && (
        <div className="mt-4 mb-20">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Review Videos</h2>
            <h3 className="text-lg font-semibold">
              {data.total_number_of_folders}{' '}
              {data.total_number_of_folders === 1 ? 'Folder' : 'Folders'} (
              {data.total_images}{' '}
              {data.total_images === 1 ? 'Image' : 'Images'})
            </h3>
          </div>
          <div
            className="relative shadow-md rounded bg-white overflow-hidden"
            style={{ height: 'calc(100vh - 320px)' }}
          >
            <div className="overflow-y-auto h-full">
              <table className="w-full">
                <thead className="sticky top-0 bg-white shadow-sm z-10">
                  <tr>
                    <th className="sticky left-0 z-30 bg-white py-2 px-4 border-b-2 border-gray-200 text-left w-32">
                      Model
                    </th>
                    <th className="sticky left-0 z-30 bg-white py-2 px-4 border-b-2 border-gray-200 text-left w-48">
                      Folder Name
                    </th>
                    <th className="z-20 bg-white py-2 px-4 border-b-2 border-gray-200 text-left">
                      Video Images
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((folder) => (
                    <tr key={folder.name}>
                      <td className="left-0 z-10 bg-white py-2 px-4 border-b border-gray-200 w-32">
                        {folder.models && folder.models.length > 0 ? (
                          <div className="flex flex-col items-center">
                            <img
                              src={folder.models[0].thumbnailUrl}
                              alt={folder.models[0].name}
                              className="object-cover"
                              style={{
                                width: '100px',
                                height: '100px',
                              }}
                            />
                            <p className="mt-1 text-center">
                              {folder.models[0].name}
                            </p>
                          </div>
                        ) : (
                          <span className="text-red-600">No model found</span>
                        )}
                      </td>
                      <td className="left-0 z-10 bg-white py-2 px-4 border-b border-gray-200 font-bold w-48">
                        {folder.name}
                        <div className="text-sm">
                          {folder.num_images}{' '}
                          {folder.num_images === 1 ? 'Image' : 'Images'}
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 max-w-0 w-full">
                        <div className="overflow-x-auto">
                          <div className="flex gap-4 items-center min-w-max">
                            {folder.images.map((image, index) => (
                              <div
                                key={image.filename}
                                className="border p-2 rounded shadow cursor-pointer flex-shrink-0"
                                onClick={() => openPreview(folder.images, index)}
                              >
                                <img
                                  src={image.url}
                                  alt={image.filename}
                                  className="object-contain"
                                  style={{
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {!data && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
          <div className="border p-4 rounded shadow">
            <h2 className="font-semibold mb-2">Step 1</h2>
            <p>Right-click the folder in Drive.</p>
          </div>
          <div className="border p-4 rounded shadow">
            <h2 className="font-semibold mb-2">Step 2</h2>
            <p>Select "Share" → "Share".</p>
          </div>
          <div className="border p-4 rounded shadow">
            <h2 className="font-semibold mb-2">Step 3</h2>
            <p>
              Give access to{' '}
              <strong>
                google-drive-bulk@rosy-algebra-450819-p8.iam.gserviceaccount.com
              </strong>
            </p>
          </div>
          <div className="border p-4 rounded shadow">
            <h2 className="font-semibold mb-2">Step 4</h2>
            <p>Choose "Viewer" permission and click "Copy link".</p>
          </div>
        </div>
      )}

      {previewImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
          style={{ zIndex: 40 }}
        >
          <div
            className="bg-white p-4 rounded shadow-lg relative"
            style={{
              width: '80%',
              maxWidth: '600px',
              height: '80%',
              maxHeight: 'calc(100vh - 260px)',
              marginBottom: '60px',
            }}
          >
            <button
              className="absolute top-2 right-2 text-black"
              onClick={closePreview}
            >
              <X className="h-6 w-6" />
            </button>
            <div className="absolute top-2 left-2 text-2xl font-bold">
              {
                data?.data.find((folder) => folder.images.includes(previewImage))
                  ?.name || ''
              }
            </div>
            <div className="flex justify-center items-center h-full">
              <img
                src={previewImage.url}
                alt={previewImage.filename}
                className="max-w-full max-h-full object-contain"
              />
            </div>
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-lg">
              Image {currentImageIndex + 1} of{' '}
              {
                data?.data.find((folder) => folder.images.includes(previewImage))
                  ?.images.length
              }
            </div>
            <div className="absolute bottom-2 right-2 flex space-x-4">
              <button
                onClick={() =>
                  showPreviousImage(
                    data?.data.find((folder) =>
                      folder.images.includes(previewImage)
                    )?.images || []
                  )
                }
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={() =>
                  showNextImage(
                    data?.data.find((folder) =>
                      folder.images.includes(previewImage)
                    )?.images || []
                  )
                }
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      )}

      {data && (
        <footer className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg py-3 px-6 w-full">
          <div className="container mx-auto flex justify-center items-center">
            <button
              className="bg-black text-white px-6 py-2 hover:bg-gray-800 rounded-lg"
              onClick={handleGenerateVideos}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? 'Processing...' : 'Generate videos'}
            </button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default BulkVideo;