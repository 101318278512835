import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useGeneratedGroups } from '../../API';
import GenerationGroup from '../../components/GenerationGroup';
import Loader from '../../assets/icons/loader.svg';
import SelectedImage from '../../components/SelectedImage';
const AllShotsView = ({  setUpscaleQueue, setCompletedUpscale,selectedModel=[],LkView=false }) => {

    const [selectedImage, setSelectedImage] = useState(null);
  const {
    data,
    isLoading,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch   // Only added this
  } = useGeneratedGroups({
    limit: 6,
    isLkView: LkView,
    payload: { models: selectedModel }
  });
  const [generatedGroups,setGeneratedGroups]=useState([]);
  useEffect(() => {
    if (LkView && selectedModel.length > 0) {
      refetch();
    }
  }, [selectedModel, LkView]); // Only changed this useEffect

  const [currentPage, setCurrentPage] = useState(1);
  const observerRef = useRef(null);
  const lastGroupRef = useRef(null);

  useEffect(()=>{
    const generatedGroupsData = data?.pages.flatMap(page => page.images) || [];
    setGeneratedGroups(generatedGroupsData);
   
  },[data])
  // Rest of your component code stays exactly the same
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    });
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    if (lastGroupRef.current && observerRef.current) {
      observerRef.current.observe(lastGroupRef.current);
    }
    return () => {
      if (lastGroupRef.current && observerRef.current) {
        observerRef.current.unobserve(lastGroupRef.current);
      }
    };
  }, [generatedGroups]);
  const handleNextImage = () => {
    if (!selectedImage || !generatedGroups.length) return;
    
    const groupIndex = generatedGroups.findIndex(g => g.generation_id === selectedImage.image.generation_id);
    const currentGroup = generatedGroups[groupIndex];
    const imageIndex = currentGroup.images.findIndex(img => img.image_id === selectedImage.image.image_id);
    
    if (imageIndex < currentGroup.images.length - 1) {
      setSelectedImage({ image: currentGroup.images[imageIndex + 1] });
    } else if (groupIndex < generatedGroups.length - 1) {
      setSelectedImage({ image: generatedGroups[groupIndex + 1].images[0] });
    }else{
      //this is to disable the next button when the last image is selected
      return true}
    //this is to keep enable the next button when the last image is selected
    return false;
  };
  
  const handlePrevImage = () => {
    if (!selectedImage || !generatedGroups.length) return;
    
    const groupIndex = generatedGroups.findIndex(g => g.generation_id === selectedImage.image.generation_id);
    const currentGroup = generatedGroups[groupIndex];
    const imageIndex = currentGroup.images.findIndex(img => img.image_id === selectedImage.image.image_id);
    if (imageIndex > 0) {
      setSelectedImage({ image: currentGroup.images[imageIndex - 1] });
    } else if (groupIndex > 0) {
      const prevGroup = generatedGroups[groupIndex - 1];
      setSelectedImage({ image: prevGroup.images[prevGroup.images.length - 1] });
    }else{
      //this is to diable the prev button when the first image is selected
      return true;
    }
    //this is to keep enable the prev button when the first image is selected
    return false;
  };
  if (isLoading && !data)
    return (
      <div className="w-full text-xl font-bold flex flex-1 h-20 justify-center items-center mt-2 mb-10">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div className="mt-4 flex flex-1 w-full h-full">
      {selectedImage && (
        <SelectedImage
          selectedImage={selectedImage}
          handleNextImage={handleNextImage}
          handlePrevImage={handlePrevImage}
          generatedGroups={generatedGroups}
          setGeneratedGroups={setGeneratedGroups}
          onClickCancel={() => setSelectedImage(null)}
        />
      )}
      <div className="flex flex-1 flex-col px-5">
        {generatedGroups.map((group, index) => (
          <GenerationGroup
            key={`${group.generation_id}-${index}`}
            generationId={group.generation_id}
            images={group.images}
            prompt={group.prompt}
            aspectRatio={group.aspect_ratio}
            setSelectedImage={setSelectedImage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLastGroup={index === generatedGroups.length - 1}
            ref={index === generatedGroups.length - 1 ? lastGroupRef : null}
            setCompletedUpscale={setCompletedUpscale}
            setUpscaleQueue={setUpscaleQueue}
            LkView={LkView}
            generatedGroups={generatedGroups}
          setGeneratedGroups={setGeneratedGroups}
          />
        ))}
        {isFetchingNextPage && (
          <div className={`grid ${LkView?"grid-cols-2":"grid-cols-4"}  gap-4 mt-4`}>
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="animate-pulse bg-gray-300 rounded-lg w-full h-64"
              ></div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default AllShotsView;